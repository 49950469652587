.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: #515a67 !important;
  width: 6px;
  height: 12px;
  margin-left: 2px;
}
.ant-checkbox-checked .ant-checkbox-inner {
  color: #000000;
  background-color: #ffffff;
  border-color: #B4B8BF;
  border-radius: 4px;
}
.ant-checkbox-inner {
  border: 1px solid #B4B8BF;
  display: flex;
  text-align: center;
  border-radius: 4px;
  width: 24px;
  height: 24px;
}
.ant-checkbox-inner:hover {
  border-radius: 4px;
  border: 1px solid #515a67;
}
.ant-checkbox {
  margin-right: 4px;
}
.ant-checkbox-input {
  border-radius: 4px;
}
ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #515a67;
}
.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #515a67;
}
.ant-checkbox-checked::after {
  display: none;
}
.ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
}