.ant-pagination-next {
  position: absolute;
  right: 24px;
  border-color: #b4b8bf;
  border-width: 1px;
  padding: 0px 8px;
  color: #515a67;
  border-radius: 6px;
}
li svg {
  margin-bottom: 4px;
}

.ant-pagination-next:hover {
  box-shadow: 0px 1px 2px 0px;
}

.ant-pagination-prev {
  position: absolute;
  left: 24px;
  border-color: #b4b8bf;
  border-width: 1px;
  padding: 0px 8px;
  color: #515a67;
  border-radius: 6px;
}

.ant-pagination-prev:hover {
  box-shadow: 0px 1px 2px 0px;
}

.ant-pagination-item {
  border-width: 0px;
  border-radius: 6px;
  color: #424954;
  font-weight: 500;
}

.ant-pagination-item:hover {
  box-shadow: 0px 1px 2px 0px;
}

.ant-table-wrapper {
  border-radius: 100px;
}
.ant-table-footer {
  background-color: white;
}
.ant-pagination-item-active {
  background-color: #f4f4f5;
  border-radius: 6px;
}
.ant-table-fixed {
  table-layout: fixed;
}

.ant-table-tbody > tr > td {
  word-wrap: break-word;
  word-break: break-all;
}
